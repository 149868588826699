<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" dense flat fixed slot="header">
          <v-btn icon @click.stop="reset;closeModal(modal_name);">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- Choose exam type -->
        <v-stepper slot="content" flat class="elevate-0" v-model="step" vertical :key="key_stepper">
          <v-stepper-step editable :complete="step > 1" step="1">
            {{ $store.getters.translate("choose_exam_type") }}
            <small class="mt-2"> {{ getExamType }}</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card class="ml-5" flat :loading="loading" :disabled="loading">
              <v-switch :disabled="disabled" v-model="record.re_examination" :label="$store.getters.translate('re_examination')"/>
              <v-chip label color="primary" v-if="record.name">
                {{ record.name }}
              </v-chip>
              <base-link v-if="record.re_examination && !disabled"
                module_name="exams"
                :label="$store.getters.translate('root_exam')"
                :single_select="true"
                 @change="addReExam"/>
              <base-link v-if="!record.re_examination && !disabled"
                module_name="schemes"
                :label="$store.getters.translate('choose_scheme')"
                :single_select="true"
                @change="addScheme"/>
            </v-card>
          </v-stepper-content>
          <!-- Add general information -->
          <v-stepper-step @click="validate" :complete="step > 2" step="2" editable>
            {{ $store.getters.translate("general_info") }}
            <small class="mt-2">{{ getGeneral }}</small>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card flat class="ml-5 mb-5">
              <v-col cols="12" class="pa-0">
                <v-text-field :class="'ma-0 pt-'+$store.getters.lineheight" :label="$store.getters.translate('name')" v-model="record.name">
                  <template v-if="usual_fields_required['name']" #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field :class="'ma-0 pt-'+$store.getters.lineheight" :label="$store.getters.translate('reference')" v-model="record.reference">
                  <template v-if="usual_fields_required['reference']" #label>{{ $store.getters.translate('reference') }}<span class="red--text"><strong>*</strong></span></template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    v-model="record.examination_date"
                    :label="$store.getters.translate('examination_date')"
                    v-mask="'##-##-####'"
                    placeholder="dd-mm-yyyy"
                    @change="checkCapacityAndExams">
                  <template v-if="usual_fields_required['examination_date']" #label>{{ $store.getters.translate('examination_date') }}<span class="red--text"><strong>*</strong></span></template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <small class="mt-2 red--text">{{ exist_exams_message }}</small>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-select
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    v-model="selected_location"
                    :label="$store.getters.translate('location')"
                    :items="$lodash.sortBy(locations, 'name')"
                    :return-object="true"
                    item-text="name"
                    item-value="id"
                    cache-items
                    :disabled="loading || disabled"
                    clearable
                    @change="getRooms">
                  <template v-if="usual_fields_required['location_id']" #label>{{ $store.getters.translate('location') }}<span class="red--text"><strong>*</strong></span></template>
                </v-select>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-select
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    v-model="selected_room"
                    :label="$store.getters.translate('room')"
                    :items="$lodash.sortBy(rooms, 'name')"
                    :return-object="true"
                    item-text="name"
                    item-value="id"
                    :disabled="loading || disabled"
                    clearable
                    @change="getAssessors">
                  <template v-if="usual_fields_required['room_id']" #label>{{ $store.getters.translate('room') }}<span class="red--text"><strong>*</strong></span></template>
                </v-select>
              </v-col>
              <v-col cols="12" class="pa-0">
                <small class="mt-2 red--text">{{ exams_message }}</small>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-select
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    v-model="selected_examinator"
                    :label="$store.getters.translate('examinators')"
                    :items="$lodash.sortBy(examinators, 'name')"
                    item-text="name"
                    item-value="id"
                    clearable>
                  <template v-if="usual_fields_required['examinators']" #label>{{ $store.getters.translate('examinators') }}<span class="red--text"><strong>*</strong></span></template>
                </v-select>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-select
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    v-model="selected_supervisor"
                    :label="$store.getters.translate('supervisors')"
                    :items="$lodash.sortBy(supervisors, 'name')"
                    item-text="name"
                    item-value="id"
                    clearable>
                  <template v-if="usual_fields_required['supervisors']" #label>{{ $store.getters.translate('supervisors') }}<span class="red--text"><strong>*</strong></span></template>
                </v-select>
              </v-col>
<!--              <v-col cols="12" class="pa-0">
                <v-select
                    :label="$store.getters.translate('exam_language')"
                    v-model="record.exam_language"
                    :items="languages"
                    item-text="text"
                    item-value="value"
                    class="mb-5"
                    clearable/>
              </v-col>-->
<!--          <v-switch :class="'ma-0 pt-'+$store.getters.lineheight" :label="$store.getters.translate('re_certification')" v-model="record.re_certification"/>-->
              <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
            </v-card>
          </v-stepper-content>
          <!-- Set exam statuses -->
<!--          <v-stepper-step @click="validate" :complete="step > 3" step="3" editable>
            {{ $store.getters.translate("status") }}
            <small class="mt-2">{{ $store.getters.translate("status") }}</small>
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card flat class="ml-5 mb-5">
              <v-switch :label="$store.getters.translate('joint_planning')" v-model="record.statuses.joint_planning"/>
              <v-switch :label="$store.getters.translate('location_requested')" v-model="record.statuses.location_requested"/>
              <v-switch :label="$store.getters.translate('location_agreed')" v-model="record.statuses.location_agreed"/>
              <v-switch :label="$store.getters.translate('practical_planned')" v-model="record.statuses.practical_planned"/>
              <v-switch :label="$store.getters.translate('examiner_requested')" v-model="record.statuses.examiner_requested"/>
              <v-switch :label="$store.getters.translate('examiner_agrees')" v-model="record.statuses.examiner_agrees"/>
              <v-switch :label="$store.getters.translate('passed_on_to_customer')" v-model="record.statuses.passed_on_to_customer"/>
              <v-switch :label="$store.getters.translate('running_projects')" v-model="record.statuses.running_projects"/>
            </v-card>
          </v-stepper-content>-->
          <!-- Choose exam sessions -->
          <v-stepper-step @click="validate" :complete="step > 3" step="3" editable>
            {{ $store.getters.translate("choose_sessions") }}
            <small class="mt-2"> {{ getSessions }}</small>
          </v-stepper-step>
          <v-stepper-content step="3">
            <template v-if="record.scheme_id" class="ml-5">
              <v-row>
                <v-col>
                  <v-chip-group v-model="record.sessions" column multiple active-class="primary--text">
                    <v-chip v-for="session in scheme_sessions" :key="session.name" :value="session">
                      {{ session.name }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </template>
          </v-stepper-content>
          <!-- Add candidates to session -->
          <template v-for="(session, index) in record.sessions">
            <v-stepper-step
                :key="index"
                @click="setActiveSession(session)"
                editable
                :complete="step > 4 + index"
                :step="4 + index">
              {{ $store.getters.translate("add_information_to") }} {{ $lodash.lowerCase(session.name) }}
            </v-stepper-step>
            <v-stepper-content :key="session.id" :step="4 + index">
              <v-card flat class="ml-5 mb-5">
                <base-link
                  @click="setActiveSession(session)"
                  @change="addCandidates"
                  module_name="candidates"
                  :label="$store.getters.translate('add_candidates')"/>
                <v-combobox
                  class="mt-5"
                  v-if="session.candidates"
                  clearable
                  :label="$store.getters.translate('candidates')"
                  v-model="session.candidates"
                  :items="session.candidates"
                  chips
                  deletable-chips
                  small-chips
                  multiple
                  outlined
                  item-value="id"
                  item-text="name"/>
                <v-btn v-if="session.candidates" @click="addToAllSessions(session.candidates)" color="primary" text>
                  {{ $store.getters.translate("add_candidates_to_all_sessions") }}
                </v-btn>
              </v-card>
            </v-stepper-content>
          </template>
        </v-stepper>
        <v-card-footer slot="footer">
          <v-btn @click="save" :disabled="loading" :loading="loading" large block tile color="primary">
            {{ $store.getters.translate("save") }}
          </v-btn>
        </v-card-footer>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseLink from "../../components/commonComponents/BaseLink";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";
import moment from "moment";

export default {
  props: ["parent", "modal_name", "disabled"],
  components: { BaseLink, BaseModal, BaseDraggableModal },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("create_exam"),
        name: "exams",
        model: "exam",
      },
      step: 1,
      active_session: null,
      examinators: [],
      supervisors: [],
      locations: [],
      rooms: [],
      selected_examinator: null,
      selected_supervisor: null,
      selected_location: null,
      selected_room: null,
      record: {
        name: "",
        description: "",
        exam_language: "nl",
        course_id: null,
        exam_company_id: null,
        examination_date: null,
        re_examination: false,
        re_certification: false,
        location_id: null,
        re_exam_id: null,
        reference: "",
        room_id: null,
        scheme_id: null,
        candidates: [],
        sessions: [],
        scheme_owner: null,
        statuses: {}
      },
      languages: [
          { text: this.$store.getters.translate('nl'), value: 'nl' },
          { text: this.$store.getters.translate('en'), value: 'en' }
      ],
      loading: false,
      re_exam: {},
      key_stepper: 0,
      usual_fields_required: [],
      tags_required: false,
      exams_for_count: [],
      scheme_sessions: [],
      candidates_count: 0,
      exams_message: '',
      exist_exams_message: '',
    };
  },
  created() {
    this.getLocations();
    this.getExaminators();
    this.getSupervisors();
    this.getFields();
  },
  methods: {
    ...helpFunctions.modal_functions,
    validate() {
      if (!this.record.scheme_id) {
        this.$toasted.error(this.$store.getters.translate("select_scheme"));
      }
    },
    save() {
      if(!this.loading) {
        let error = false;
        for (let key in this.usual_fields_required) {
          if (key === 'examinators') {
            if(this.usual_fields_required['examinators'] && !this.selected_examinator) {
              this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(key));
              error = true;
            }
          }
          else if (key === 'supervisors') {
            if(this.usual_fields_required['supervisors'] && !this.selected_supervisor) {
              this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(key));
              error = true;
            }
          }
          else if (this.usual_fields_required[key] && (!this.record[key] || this.record[key] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(key));
            error = true;
          }
        }
        if (!error) {
          if (!this.record.sessions || this.record.sessions.length === 0) {
            this.$toasted.error(this.$store.getters.translate("please_select_sessions"));
          }
          else if(this.selected_room && this.selected_room.maximum_capacity && this.active_session && this.active_session.candidates &&
              this.selected_room.maximum_capacity < (this.active_session.candidates.length + this.candidates_count)) {
            this.$toasted.error(this.$store.getters.translate("room_too_small"));
          }
          else {
            this.loading = true;
            this.record.sessions.forEach((session) => {
              if (this.selected_examinator && session.name === 'Praktijk') {
                session.examinator = this.selected_examinator;
              }
              if (this.selected_supervisor && (session.name === 'Basistoets' || session.name === 'Certificatietoets' || session.name === 'Schakelbericht')) {
                session.supervisor = this.selected_supervisor;
              }
              session.location_id = this.record.location_id;
              session.room_id = this.record.room_id;
            });
            this.$http
                .post(this.$store.getters.appUrl + "v2/exams", this.record)
                .then((response) => {
                  if (response.status == 200) {
                    this.$http.post(this.$store.getters.appUrl + "v2/save-candidates", {
                      record: this.record,
                      exam_id: response.data.id
                    });
                    this.loading = false;
                    this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                    this.reset();
                    this.closeModal(this.modal_name);
                    this.$router.push("/exams/" + response.data.id);
                  } else {
                    var errorMessage = response.data.message;
                    var errors = Object.keys(response.data.message);
                    this.$toasted.error([errorMessage[errors[0]]]);
                    this.loading = false;
                  }
                })
                .catch((error) => {
                  if (this.$store.getters.isLoggedIn) {
                    this.$toasted.error(error);
                  }
                  this.loading = false;
                });
          }
        }
      }
    },
    reset() {
      this.step = 1;
      this.record = {
        name: "",
        description: "",
        exam_language: "nl",
        course_id: null,
        exam_company_id: null,
        examination_date: null,
        re_examination: false,
        re_certification: false,
        location_id: null,
        re_exam_id: null,
        reference: "",
        room_id: null,
        scheme_id: null,
        candidates: [],
        sessions: [],
        scheme_owner: null,
        statuses: {}
      };
    },
    addCandidates(objects) {
      if (!this.active_session.candidates) {
        this.active_session.candidates = [];
      }
      if(this.selected_room && this.selected_room.maximum_capacity && this.selected_room.maximum_capacity < (objects.length + this.active_session.candidates.length + this.candidates_count)) {
        this.$toasted.error(this.$store.getters.translate("room_too_small"));
      }
      else if(this.active_session.max_candidates && this.active_session.max_candidates < (objects.length + this.active_session.candidates.length)) {
        this.$toasted.error(this.active_session.name + ": " + this.$store.getters.translate("session_too_small"));
      }
      else {
        objects.forEach((candidate) => {
          candidate["candidate_id"] = candidate["id"];
          this.active_session.candidates.push(candidate);
        });
        this.key_stepper = this.key_stepper + 1;
      }
    },
    addReExam(exam) {
      this.$http
          .get(this.$store.getters.appUrl + "v2/exams/" + exam.id)
          .then((response) => {
            this.loading = false;
            this.reset();
            this.re_exam = response.data;
            this.record.name = this.re_exam.name;
            this.record.description = this.re_exam.description;
            this.record.course_id = null;
            this.record.exam_company_id = this.re_exam.exam_company_id;
            this.record.re_examination = true;
            this.record.re_certification = false;
            this.record.re_exam_id = this.re_exam.id;
            this.record.candidates = [];
            this.record.sessions = [];
            this.record.scheme_id = this.re_exam.scheme_id;
            this.record.scheme = this.re_exam.scheme;
            this.record.scheme_owner = this.re_exam.scheme.scheme_owner;
            this.record.tags = [];
            this.re_exam.scheme.tags.forEach((tag) => {
              if(tag !== "gepubliceerd") {
                this.record.tags.push(tag);
              }
            });
            this.scheme_sessions = [];
            this.usual_fields_required['room_id'] = false;
            this.record.scheme.scheme_sessions.forEach((scheme_session) => {
              scheme_session.scheme_session_id = scheme_session.id;
              this.scheme_sessions.push(scheme_session);
              if(scheme_session.name === "Praktijk") {
                this.usual_fields_required['room_id'] = true;
              }
            });
            this.loadSessions();
            this.getLocations();
            this.step = 2;
          })
          .catch((error) => {
            this.loading = false;
            this.$toasted.error(error);
          });
    },
    addScheme(scheme) {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/schemes/" + scheme.id)
          .then((response) => {
            this.loading = false;
            this.step = 1;
            this.record.name = response.data.exam_name ? response.data.exam_name : response.data.name;
            this.record.description = "";
            this.record.course_id = null;
            this.record.exam_company_id = null;
            this.record.re_examination = false;
            this.record.re_certification = false;
            this.record.re_exam_id = null;
            this.record.reference = "";
            this.record.candidates = [];
            this.record.sessions = [];
            this.record.scheme_id = response.data.id;
            this.record.scheme = response.data;
            this.record.scheme_owner = response.data.scheme_owner;
            this.record.tags = [];
            response.data.tags.forEach((tag) => {
              if(tag !== "gepubliceerd") {
                this.record.tags.push(tag);
              }
            });
            this.scheme_sessions = [];
            this.usual_fields_required['room_id'] = false;
            this.record.scheme.scheme_sessions.forEach((scheme_session) => {
              scheme_session.scheme_session_id = scheme_session.id;
              this.scheme_sessions.push(scheme_session);
              if(scheme_session.name === "Praktijk") {
                this.usual_fields_required['room_id'] = true;
              }
            });
            this.getLocations();
            this.step = 2;
          })
          .catch((error) => {
            this.loading = false;
            this.$toasted.error(error);
          });
    },
    loadSessions() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/exams/" + this.re_exam.id + "/sessions")
        .then((response) => {
          this.re_exam.sessions = response.data;
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    setActiveSession(session) {
      session.scheme_session_id = session.id;
      this.active_session = session;
      if (this.record.re_examination) {
        this.re_exam.sessions.forEach((session) => {
          session.exam_candidate_sessions.forEach((exam_candidate_session) => {
            if (session.scheme_session_id === this.active_session.scheme_session_id && exam_candidate_session.status !== "exempt" &&
                (exam_candidate_session.status === "absent" || (exam_candidate_session.result && parseFloat(exam_candidate_session.result.replace(",", ".")) < parseFloat(session.cesuur.replace(",", "."))))) {
              exam_candidate_session.candidate["candidate_id"] = exam_candidate_session.candidate["id"];
              if (!this.active_session.candidates) {
                this.active_session.candidates = [];
              }
              let exists = this.active_session.candidates.find((candidate) => candidate.id === exam_candidate_session.candidate_id);
              if (!exists) {
                this.active_session.candidates.push(exam_candidate_session.candidate);
              }
            }
          });
        });
      }
    },
    addToAllSessions(candidates) {
      let error = false;
      this.record.sessions.forEach((session) => {
        if(session.max_candidates && session.max_candidates < candidates.length) {
          this.$toasted.error(session.name + ": " + this.$store.getters.translate("session_too_small"));
          error = true;
        }
        else {
          session.candidates = candidates;
          this.setActiveSession(session);
        }
      });
      if(!error) {
        this.step = 10;
        this.$toasted.success(this.$store.getters.translate("candidates_have_been_added_to_all_sessions"));
      }
    },
    getLocations() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/locations")
          .then((response) => {
            this.loading = false;
            this.locations = response.data.data;
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
      /*this.record.location_id = null;
      this.record.room_id = null;
      this.examinators = [];
      this.supervisors = [];
      this.rooms = [];
      this.selected_examinator = null;
      this.selected_supervisor = null;
      this.selected_location = null;
      this.selected_room = null;
      this.locations = helpFunctions.getLocations(this.record.scheme, 'exam');*/
    },
    getRooms() {
      this.record.location_id = this.selected_location.id;
      if (this.record.location_id) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/location-rooms", { id: this.record.location_id })
            .then((response) => {
              this.rooms = response.data;
              this.loading = false;
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      } else {
        return [];
      }
      /*this.record.location_id = this.selected_location.id;
      this.record.room_id = null;
      this.examinators = [];
      this.supervisors = [];
      this.selected_examinator = null;
      this.selected_supervisor = null;
      this.selected_room = null;
      this.rooms = helpFunctions.getRooms(this.record.scheme, this.record.location_id, 'exam');*/
    },
    getAssessors() {
      this.record.room = this.selected_room;
      this.record.room_id = this.selected_room.id;
      this.selected_examinator = null;
      this.selected_supervisor = null;
      //this.examinators = helpFunctions.getAssessors(this.record.scheme, this.record.room_id, "examinator");
      //this.supervisors = helpFunctions.getAssessors(this.record.scheme, this.record.room_id, "supervisor");
      this.checkCapacityAndExams();
    },
    async getExaminators() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/assessors?type=examinator")
          .then((response) => {
            this.examinators = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async getSupervisors() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/assessors?type=supervisor")
          .then((response) => {
            this.supervisors = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    updateLRA() {
      this.locations = helpFunctions.getLocations(this.record.scheme, 'exam');
      this.rooms = helpFunctions.getRooms(this.record.scheme, this.record.location_id, 'exam');
      this.examinators = helpFunctions.getAssessors(this.record.scheme, this.record.room_id, "examinator");
      this.supervisors = helpFunctions.getAssessors(this.record.scheme, this.record.room_id, "supervisor");
    },
    checkCapacityAndExams() {
      if (this.record.room_id && this.record.examination_date && moment(this.record.examination_date, "DD-MM-YYYY", true).isValid()) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/room-exams", { id: this.record.room_id, examination_date: this.record.examination_date })
            .then((response) => {
              this.exams_for_count = response.data;
              this.candidates_count = 0;
              this.exams_for_count.forEach((exam) => {
                this.candidates_count += exam.candidates.length;
              });
              this.exams_message = this.exams_for_count.length + ' exams with ' + this.candidates_count + ' candidates planned in ' + this.selected_room.name + ' on ' + this.record.examination_date;
              this.loading = false;
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        this.$http
            .post(this.$store.getters.appUrl + "v2/exist-exams", {
              examination_date: this.record.examination_date,
              reference: this.record.reference,
              scheme_id: this.record.scheme_id
            })
            .then((response) => {
              if(response.data.length > 0) {
                this.exist_exams_message = response.data.length  + ' ' + this.$store.getters.translate("exam_exists")  + ' ' + response.data.join(", ");
              }
              else {
                this.exist_exams_message = '';
              }
              this.loading = false;
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      }
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_creation=true&model_type=" + this.page.model)
          .then((response) => {
            this.usual_fields_required = [];
            response.data.data.forEach((field) => {
              if(field.name === 'tags') {
                this.tags_required = true;
              }
              else if(field.name === 'location_name') {
                this.usual_fields_required['location_id'] = field.mandatory;
              }
              else if(field.name === 'room_name') {
                this.usual_fields_required['room_id'] = field.mandatory;
              }
              else if(field.name === 'scheme_name') {
                this.usual_fields_required['scheme_id'] = field.mandatory;
              }
              else if(field.field_type !== 'boolean' && !field.custom_field) {
                this.usual_fields_required[field.name] = field.mandatory;
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  },
  computed: {
    getSessions() {
      if (this.record.sessions) {
        return this.$lodash.map(this.record.sessions, "name").join(", ");
      }
      return "";
    },
    getGeneral() {
      let name = this.record.name;
      let reference = this.record.reference ? ", " + this.record.reference : "";
      let examination_date = this.record.examination_date ? ", " + moment(this.record.examination_date).format("DD-MM-YYYY") : "";
      let re_certification = this.record.re_certification ? ", " + this.$store.getters.translate("re_certification") : "";
      if (this.record.name) {
        return name + reference + examination_date + re_certification;
      }
      return "";
    },
    getExamType() {
      return this.record.re_examination ? this.$store.getters.translate("re_examination") : this.$store.getters.translate("new_exam");
    },
  },
};
</script>

<style scoped>
.content-wrapper{
  height: inherit;
  max-height: inherit;
}
</style>